import React, { Component, Fragment } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../../utils/localStorage';
import { Link } from 'react-router-dom'
import { Form, Select, Modal, Tooltip } from 'antd';
import PDFImg from '../../../assets/images/ic-pdf.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRadiationAlt } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DischargeIcon from "../../../assets/images/discharge.png";
import HighAlertIcon from "../../../assets/images/highalert.png";
import InfoIcon from "../../../assets/images/info.png";
import { ipdListExistingOrders } from "../../../utils/OrderResponse";
import ResultEntryEditors from '../../patient-detail/mrdcomponents/ResultEntryEditors';
import {
  LOADER_RED,
  PATIENT_OP_MEDICINE_LIST,
  PATIENT_OP_TEST_LIST,
  LAB_TEST_CONSTANTS,
  PATIENT_IP_ORDER_LIST,
  PATIENT_IP_MEDICINE_ORDER_LIST,
  PATIENT_IP_OTHER_ORDER_LIST,
  PATIENT_IP_EQUIPMENT_ORDER_LIST,
  DRUGS_CONSTANT_IPD,
  GET_PROCEDURE_TYPE_SERVICE_DATA,
  GET_LIS_ORDER_RESULT_DATA
} from '../../../utils/constant';
import moment from 'moment';
import Moment from 'react-moment';
import MedicineTable from "../../../components/drugs/medicine-table";
import TestTable from "../../../components/tests/test-table";
import qs from 'qs';
import EyeIcon from '../../../assets/images/icon-eye.svg'

const { Option } = Select;

export default class PatientOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEncounterData: props.selectedEncounterData,
      patientBannerData : props.patientBannerData,
      loader: true,
      allOpMedcineList : [],
      allOpTestList : [],
      drugsConstants: null,
      testConstants: null,
      ipd_drug_constants: null,
      allIpTestList : [],
      allIpMedicineList : [],
      allIpEquipmentOrderList: [],
      allIpOtheOrderList: [],
      isTestModalInteractionVisible: false,
      commentValue: null,
      frequency_master_list: [],
      morning_time: '09:00:00',
      afternoon_time: '13:00:00',
      evening_time: '16:00:00',
      night_time: '20:00:00',
      show_schedule_med_order: true,
      orderDetailsModal:false,
      orderTimelineModal:false,
      ServiceDetailsData: [],
      ShowPopUP:false,
      reultLink:null,
    }
    this.ExistingOrdersListArr = [];
  }

  async componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    if(this.state.selectedEncounterData.encounterTypeCode == "OP")
    {
      await this.loadData();
      await this.getPatientOpTestData();
      await this.getPatientOpMedData();
    }
    else
    {
      this.loadIpdMedData();
      await this.getPatientIPTestData();
      await this.getPatientIPMedicineData();
      await this.getPatientIPEquipmentOrderData();
      await this.getPatientIPOtherOrderData();
    }
    
  }

  loadData = async () => {
    let { testConstants } = this.state;

    // Test Constants
    const test_contants = await Axios.get(LAB_TEST_CONSTANTS);
    if (test_contants.data.hasOwnProperty("data") === true) {
      test_contants["radiologyViews"] = test_contants.data?.constants?.radiologyViews;
      test_contants["drugUrgency"] = test_contants.data?.constants?.testUrgency;
    }
    await this.setState({
      testConstants: test_contants
    });
  };

  loadIpdMedData = async () => {
    var ipd_drug_constants;
    const drugsConstantsPromise = Axios.post(DRUGS_CONSTANT_IPD, {});
    Axios.all([drugsConstantsPromise])
      .then(Axios.spread((...responses) => {
        const response = responses[0];
        if (response.data.hasOwnProperty("data") === true) {
          ipd_drug_constants = response.data.data;
        }
        this.setState({
          ipd_drug_constants
        })
      }))
  }

  async getPatientOpMedData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patientId: selectedEncounterData.registrationId,
      encounter_Id: selectedEncounterData.encounter_id,
      enterprise_id: selectedEncounterData.enterpriseId,
      facility_id: selectedEncounterData.facilityId,
    }
    await Axios.post(PATIENT_OP_MEDICINE_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let medicine_list =  res.status ? res?.data?.data?.details : [];
      let constants =  res.status ? res?.data?.data?.constants : null;
      await this.setState({
        loader: false,
        allOpMedcineList : medicine_list,
        drugsConstants: constants
      });
    })
  }

  showTestModalInteraction = (val) => {
    let showInteractionModal = true;
    this.setState({ commentValue: val, isTestModalInteractionVisible: showInteractionModal });
  };

  handleTestInteractionCancel = () => { this.setState({ isTestModalInteractionVisible: false, commentValue: null }) };

  async getPatientOpTestData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patientId: selectedEncounterData.registrationId,
      encounter_Id: selectedEncounterData.encounter_id,
      enterprise_id: selectedEncounterData.enterpriseId,
      facility_id: selectedEncounterData.facilityId,
    }
    await Axios.post(PATIENT_OP_TEST_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let test_list =  res.status ? res?.data?.data?.details : [];
      console.log(test_list);
      await this.setState({
        loader: false,
        allOpTestList : test_list
      });
    })
  }

  async getPatientIPTestData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patientId: selectedEncounterData.registrationId,
      encounterId: selectedEncounterData.encounter_id,
      facilityId: selectedEncounterData.facilityId,
      timezoneOffsetMinutes: 330,
      status: 0,
      service_type: 'both',
    }
    await Axios.post(PATIENT_IP_ORDER_LIST, qs.stringify(PARAMS))
    .then(async response => {
      if (response?.data?.data?.length > 0) {
        var orderData = ipdListExistingOrders(response.data);
        if (orderData.data.data.length > 0) {
          var investigations = orderData.data.data.filter((v, i) => v.serviceCategoryCode == "I" || v.serviceCategoryCode == "P" || v.serviceCategoryCode == "O" || v.serviceCategoryCode == "IPP");
          await this.setState({
            allIpTestList : investigations
          });
        }
      }
      await this.setState({ loader: false });
    })
  }

  async getPatientIPMedicineData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      patientId: selectedEncounterData.registrationId,
      encounterId: selectedEncounterData.encounter_id,
      facilityId: selectedEncounterData.facilityId,
      timezoneOffsetMinutes: 330,
      status: 0,
      service_type: 'drugs',
    }
    await Axios.post(PATIENT_IP_MEDICINE_ORDER_LIST, qs.stringify(PARAMS))
    .then(async response => {
      if (response?.data?.data?.length > 0) {
        var orderData = ipdListExistingOrders(response.data);
        let frequency_master_list = response.status ? response?.data?.dailyFrequencyData?.data : [];
        if (orderData.data.data.length > 0 && frequency_master_list.length) {
          this.ExistingOrdersListArr = orderData.data.data.filter((v, i) => v.serviceCategoryCode != "I" && v.active_medicine == false);
          if (this.ExistingOrdersListArr.length > 0) {
            let medicines = [];
            for (var mdata of this.ExistingOrdersListArr) {
              let frequency = frequency_master_list.filter((d) => d.id == mdata.daily_frequency);
              mdata.frequency_type = 'Normal';
              if (frequency.length) {
                frequency = frequency[0];
                if (frequency.frequencyNature == 'PRN') {
                  mdata.frequency_type = 'PRN';
                } else if (frequency.frequencyNature == 'Once') {
                  mdata.frequency_type = 'Once';
                }
                mdata.daily_frequency_obj = frequency;

                if (mdata.timeslots == undefined || mdata?.timeslots?.length == 0) {
                  if (frequency.frequencyNature == 'Frequency' && frequency.interval_per == 'Days') {
                    if (frequency.systemCalculate == true) {
                      let timeslots = this.addTimeSlots(frequency.noofrepeats);
                      timeslots = timeslots.map((t) => {
                        return { scheduletime: t }
                      });
                      mdata.timeslots = timeslots;
                    } else if (frequency.systemCalculate == false) {
                      let timeslots = frequency.rowFrequenceIntervalData;
                      mdata.timeslots = timeslots;
                    }
                  } else if (frequency.frequencyNature == 'Frequency' && frequency.interval_per == 'Weeks') {
                    let drug_schedules = Array.isArray(mdata.schedules) ? mdata.schedules : mdata.schedules.split(",");
        
                    let time = this.state.morning_time.split(":");
                    if (drug_schedules[0] == 2) {
                      time = this.state.afternoon_time.split(":");
                    } else if (drug_schedules[0] == 3) {
                      time = this.state.evening_time.split(":");
                    } else if (drug_schedules[0] == 4) {
                      time = this.state.night_time.split(":");
                    }
                    mdata.timeslots = [{ scheduletime: time[0] + ":" + time[1] }];
                  } else {
                    mdata.timeslots = [];
                  }
                } else {
                  if (frequency.frequencyNature == 'PRN' || frequency.frequencyNature == 'Once') {
                    mdata.timeslots = [];
                  }
                }

                let allowed_dates = [];
                if (mdata.frequency_type == 'Normal') {
                  if (frequency.frequencyNature == 'Frequency' && frequency.interval_per == 'Weeks') {
                    if(frequency.noofrepeats == 1 && parseInt(mdata.number_of_days) > 0){
                      for(let k = 0; k < parseInt(mdata.number_of_days); k++){
                        allowed_dates.push(moment(mdata.startmedicationdatetime, 'YYYY-MM-DDTHH:mm:ss').add(7 * k, 'days').startOf('day').format('YYYY-MM-DD'));
                      }
                    }
                    if(frequency.noofrepeats == 2 && parseInt(mdata.number_of_days) > 0){
                      for(let k = 0; k < parseInt(mdata.number_of_days); k++){
                        allowed_dates.push(moment(mdata.startmedicationdatetime, 'YYYY-MM-DDTHH:mm:ss').add(7 * k, 'days').startOf('day').format('YYYY-MM-DD'));
                        allowed_dates.push(moment(mdata.startmedicationdatetime, 'YYYY-MM-DDTHH:mm:ss').add((7 * k) + 3, 'days').startOf('day').format('YYYY-MM-DD'));
                      }
                    }
                    if(frequency.noofrepeats == 3 && parseInt(mdata.number_of_days) > 0){
                      for(let k = 0; k < parseInt(mdata.number_of_days); k++){
                        allowed_dates.push(moment(mdata.startmedicationdatetime, 'YYYY-MM-DDTHH:mm:ss').add(7 * k, 'days').startOf('day').format('YYYY-MM-DD'));
                        allowed_dates.push(moment(mdata.startmedicationdatetime, 'YYYY-MM-DDTHH:mm:ss').add((7 * k) + 2, 'days').startOf('day').format('YYYY-MM-DD'));
                        allowed_dates.push(moment(mdata.startmedicationdatetime, 'YYYY-MM-DDTHH:mm:ss').add((7 * k) + 4, 'days').startOf('day').format('YYYY-MM-DD'));
                      }
                    }
                  }
                }
                let endmedicationdatetime_to_show = mdata.endmedicationdatetime;
                if(mdata.give_stat_dose_now != true){
                  endmedicationdatetime_to_show = this.calculateEndMedicationDateTimeToShow(mdata, allowed_dates);
                }
                mdata.endmedicationdatetime_to_show = endmedicationdatetime_to_show;
                //mdata.endmedicationdatetime = endmedicationdatetime_to_show;
                mdata.endMediDate = endmedicationdatetime_to_show;
                medicines.push(mdata);
              }
            }
            this.ExistingOrdersListArr = medicines;
            this.setState({ allIpMedicineList: this.ExistingOrdersListArr });
          }
        }
      }
      await this.setState({ loader: false });
    })
  }

  calculateEndMedicationDateTimeToShow = (mdata, allowed_dates) => {

    let end_medication_date_time = mdata.endmedicationdatetime;

    if (mdata.frequency_type != 'Normal') {
      // Do nothing
    } else {
      if (mdata.daily_frequency_obj.frequencyNature == 'Frequency' && mdata.daily_frequency_obj.interval_per == 'Days') {

        let one_slot_before_date_time = null;
        let enddatetime_moment = moment(mdata.endmedicationdatetime, 'YYYY-MM-DDTHH:mm:ss').second(0)
        for (let i = 0; i < mdata.timeslots.length; i++) {

          let end_date_with_slot_time_moment = moment(enddatetime_moment.format('YYYY-MM-DD') + 'T' + mdata.timeslots[i].scheduletime, 'YYYY-MM-DDTHH:mm');

          if (end_date_with_slot_time_moment.isBefore(enddatetime_moment)) {
            one_slot_before_date_time = end_date_with_slot_time_moment;
          }

        }

        if (one_slot_before_date_time != null) {
          end_medication_date_time = one_slot_before_date_time.format('YYYY-MM-DDTHH:mm:ss');
        } else {
          if (mdata.frequency_id == 2 && mdata.days_in_week && mdata.days_in_week.length) {
            let days_in_week = mdata.days_in_week.split(",").map((d) => parseInt(d));
            let startdatetime_moment = moment(mdata.startmedicationdatetime, 'YYYY-MM-DDTHH:mm:ss').second(0);

            let day_on_start_date = startdatetime_moment.day();
            if(day_on_start_date == 0){
              day_on_start_date = 7;
            }
            let last_day = this.findLastDay(day_on_start_date, days_in_week);
            let diff = last_day - day_on_start_date;
            if(diff >= 0){
              end_medication_date_time = moment(startdatetime_moment.add(((parseInt(mdata.number_of_days) - 1) * 7) + diff, 'day').format('YYYY-MM-DD') + 'T' + mdata.timeslots[mdata.timeslots.length - 1].scheduletime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ss');
            } else {
              end_medication_date_time = moment(startdatetime_moment.add(((parseInt(mdata.number_of_days) - 1) * 7) + (7 + diff), 'day').format('YYYY-MM-DD') + 'T' + mdata.timeslots[mdata.timeslots.length - 1].scheduletime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ss');
            }
          } else {
            let one_day_before_time = enddatetime_moment.add(-1, "day");
            end_medication_date_time = moment(one_day_before_time.format('YYYY-MM-DD') + 'T' + mdata.timeslots[mdata.timeslots.length - 1].scheduletime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ss');
          }
        }
      } else if (mdata.daily_frequency_obj.frequencyNature == 'Frequency' && mdata.daily_frequency_obj.interval_per == 'Weeks') {
        end_medication_date_time = moment(allowed_dates[allowed_dates.length - 1] + 'T' + mdata.timeslots[mdata.timeslots.length - 1].scheduletime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ss');
      }
    }
    return end_medication_date_time;
  }

  findLastDay = (today_day_number, days_in_week) => {
    let last_day = 0;
    for (let i = 0; i < days_in_week.length; i++) {
      if (days_in_week[i] < today_day_number) {
        last_day = days_in_week[i];
      }
    }
    if(last_day == 0){
      last_day = days_in_week[days_in_week.length -1]
    }
    return last_day;
  }

  async getPatientIPOtherOrderData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      encounterId: selectedEncounterData.encounter_id,
      active: 0
    }
    await Axios.post(PATIENT_IP_OTHER_ORDER_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let other_order_list =  res.status ? res?.data?.data : []
      await this.setState({
        loader: false,
        allIpOtheOrderList : other_order_list
      });
    })
  }

  async getPatientIPEquipmentOrderData() {
    const { selectedEncounterData } = this.state;
    let PARAMS = {
      encounterId: selectedEncounterData.encounter_id,
      enterpriseId: selectedEncounterData.enterpriseId,
      facilityId: selectedEncounterData.facilityId,
      registrationId: selectedEncounterData.registrationId,
    }
    await Axios.post(PATIENT_IP_EQUIPMENT_ORDER_LIST, qs.stringify(PARAMS))
    .then(async res => {
      let other_order_list =  res.status ? res?.data?.data : []
      await this.setState({
        loader: false,
        allIpEquipmentOrderList : other_order_list
      });
    })
  }

  PatientIpOtherOrderLoad() {
    try {
      if ((!this.state.allIpOtheOrderList || this.state.allIpOtheOrderList.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={7}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      const { allIpOtheOrderList } = this.state;
      return allIpOtheOrderList.map((result, i) => {
        let htmlData = (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{result.orderNo}</td>
            <td>{result.orderingPractitionerName}</td>
            <td>{moment(result.enteredDate).format('DD MMM YYYY | hh:mm A ')}</td>
            <td>{result.nursingUnitName}</td>
            <td>{result.priorityName}</td>
            <td>{result.description}</td>
          </tr>
        )
        return htmlData;
      });
    } catch (err) {
      console.log('PatientIpOtherOrderLoad: ', err);
    }
  }

  ShowPoupPDF=(reultLink)=>{
    this.setState({ ShowPopUP: true,reultLink:reultLink })
  }

  ServiceDetailsDataLoad() {
    try {
      return this.state.ServiceDetailsData.map((a) => {
        let htmlData = (
          <>
            <div className='row mt-3 d-flex align-items-center'>
              <div className='col'>
                <label className='font-weight-bold text-dark text-uppercase'>{a.component_name}</label>
              </div>
              <div className='col-3'>
                <span className='text-gray mr-2'>Status:</span>
                <span className='font-weight-bold text-dark'>{a.test_status != null ? a.test_status : "-"}</span>
              </div>
              <div className='col-3'>
                <span className='text-gray mr-2'>Order Date:</span>
                <span className='font-weight-bold text-dark'>{ moment(a.serviceorder_date).format('D MMM YYYY | hh:mm A')}</span>
              </div>
              <div className='col-auto text-right'>
                {(a.test_resultLink != null && a.test_resultLink != "") && (a.test_status_code == 'RD' || a.test_status_code == 'Finalized') ?
                  <button className='btn btn-outline-primary ml-2'  onClick={() => this.ShowPoupPDF(a.test_resultLink)}>PRINT</button>
                  :
                  null
                }
                {a.imageURL != null && a.imageURL != "" ?
                  <a href={a.imageURL} target="_blank"><button className='btn btn-outline-primary ml-2'>Scan</button></a>
                  :
                  null
                }
                {/*<Tooltip placement="top" title={'Order Timeline'}>
                <a className='nostyle-link mr-3' onClick={this.handleserviceTimeLine.bind(this)}>
                    <img src={EyeIcon} alt="iconeye" style={{ width: '20px', height: 'auto' }} />
                </a>
                </Tooltip>*/}
                {(a.result_link != null && a.result_link != "") ?
                  <button className='btn btn-outline-primary mr-2' title="Print full report" onClick={() =>this.ShowPoupPDF(a.result_link)}>PRINT ORDER</button>
                  :
                  null
                }
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-12'>
                <div className="table-responsive scrollable_sub_table">
                  <table className='table table-bordered '>
                    <thead>
                      {(a.test_type !== null && a.result_value_type.toUpperCase() == 'TEXT') ?
                        <tr>
                          <th width="40%" className='text-left'>TEST NAME</th>
                          <th width="20%" className='text-left'>RESULT</th>
                          <th width="20%" className='text-left'>UNIT</th>
                          <th width="20%" className='text-left'>REFERENCE INTERVAL</th>
                        </tr>
                        :
                        ""
                      }
                    </thead>
                    <tbody>
                      {a.departmentType === "RIS" ?
                        (
                            <object width="100%" height="500" data={a.result_link} type="application/pdf"> </object>
                        )
                        :
                        (
                          a.result_value_type.toUpperCase() === 'TEXT' ?
                          (
                              a.test_type === "GRP" ?
                                  (
                                    a.ordDtl.map((b) =>
                                    (
                                      <>
                                          <tr>
                                              <td className={(typeof b.ordDtlPara !== 'undefined' && b.ordDtlPara.length > 0) ? "text-dark font-weight-bold" : ""} >{b.component_name}</td>
                                              {/* {(a.addendum !== null && a.addendum !== "" && a.addendum !== undefined) ? <td align="right" className={b.isAbnormal.toUpperCase() === "P" ? "text-danger" : b.isAbnormal.toUpperCase() === "N" ? "text-success" : "text-dark font-weight-medium"}>{(b.result_value_type.toLowerCase() === "text") ? (b.isAbnormal === "P" ? <u>{b.test_value_old}</u> : b.test_value_old) : <a href={b.test_value_old} target="_blank">{b.test_value_old}</a>}</td> : ''} */}
                                              <td align="right" className={(b.isAbnormal === "P" || b.isAbnormal === "C") ? "text-danger" : b.isAbnormal === "N" ? "text-success" : "text-warning font-weight-medium"}>{(b.result_value_type.toLowerCase() === "text") ? ((b.isAbnormal === "P" || b.isAbnormal === "C") ? <u>{b.test_value}</u> : b.test_value) : <a href={b.test_value} target="_blank">{b.test_value}</a>}</td>
                                              <td> {b.uomCode}</td>
                                              <td style={{ whiteSpace: "pre" }}>{b.reference_range.replaceAll("<br/>", "<br>").split("<br>").join("\r\n")}</td>
                                          </tr>
                                          {(typeof b.ordDtlPara !== 'undefined' && b.ordDtlPara.length > 0) ?
                                            b.ordDtlPara.map((c) =>
                                            (
                                              <tr>
                                                  <td >{c.component_name}</td>
                                                  {/* {(a.addendum !== null && a.addendum !== "" && a.addendum !== undefined) ? <td align="right" className={c.isAbnormal.toUpperCase() === "P" ? "text-danger" : c.isAbnormal.toUpperCase() === "N" ? "text-success" : "text-dark font-weight-medium"}>{(c.result_value_type.toLowerCase() === "text") ? (c.isAbnormal === "P" ? <u>{c.test_value_old}</u> : c.test_value_old) : <a href={c.test_value_old} target="_blank">{c.test_value_old}</a>}</td> : ''} */}
                                                  <td align="right" className={(c.isAbnormal === "P" || c.isAbnormal === "C") ? "text-danger" : c.isAbnormal === "N" ? "text-success" : "text-warning font-weight-medium"}> {(c.result_value_type.toLowerCase() === "text") ? ((c.isAbnormal === "P" || c.isAbnormal === "C") ? <u>{c.test_value}</u> : c.test_value) : <a href={c.test_value} target="_blank">{c.test_value}</a>}</td>
                                                  <td> {c.uomCode}</td>
                                                  <td style={{ whiteSpace: "pre" }}>{c.reference_range.replaceAll("<br/>", "<br>").split("<br>").join("\r\n")}</td>
                                              </tr>)) : <></>}
                                      </>
                                    )
                                    )
                                  )
                                  :
                                  a.test_type !== null ?
                                      (
                                          <tr>
                                              <td>{a.component_name}</td>
                                              {/* {(a.addendum !== null && a.addendum !== "" && a.addendum !== undefined) ? <td align="right" className={a.isAbnormal.toUpperCase() === "P" ? "text-danger" : a.isAbnormal.toUpperCase() === "N" ? "text-success" : "text-dark font-weight-medium"}>{(a.result_value_type.toLowerCase() === "text") ? (a.isAbnormal === "P" ? <u>{a.test_value_old}</u> : a.test_value_old) : <a href={a.test_value_old} target="_blank">{a.test_value_old}</a>}</td> : ''} */}
                                              <td className={a.isAbnormal.toUpperCase() === "P" ? "text-danger" : a.isAbnormal.toUpperCase() === "N" ? "text-success" : "text-warning font-weight-medium"}>{(a.result_value_type.toLowerCase() === "text") ? ((a.isAbnormal === "P" || a.isAbnormal === "C") ? <u>{a.test_value}</u> : a.test_value) : <a href={a.test_value} target="_blank">{a.test_value}</a>}</td>
                                              <td> {a.uomCode}</td>
                                              <td style={{ whiteSpace: "pre" }}> {a.reference_range.replaceAll("<br/>", "<br>").split("<br>").join("\r\n")}</td>
                                          </tr>
                                      )
                                      :
                                      <></>
                          )
                          :
                          (
                              <object width="100%" height="500" data={
                                  (a.result_value_type === 'text') ? a.test_value :
                                      ((this?.props?.is_provisional_report_downloadble == false || this?.props?.is_provisional_report_downloadble == true) && this?.props?.orderStatus == "Result Provisional" && this?.props?.lisType == "MIRTH") ?
                                          (this?.props?.is_provisional_report_downloadble == true ? a.test_resultLink : a.test_resultLink + '#toolbar=0')
                                          : a.test_resultLink} type="application/pdf"> </object>
                          )
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )
        return htmlData;
      });
    } catch (err) {
      console.log('ServiceDetailsDataLoad: ', err);
    }
  }

  handleserviceTimeLine() {
    this.setState({ orderTimelineModal: true })
  }

  PatientIpEquipmentOrderLoad() {
    try {
      if ((!this.state.allIpEquipmentOrderList || this.state.allIpEquipmentOrderList.length === 0)) {
        let htmlData = (
          <tr>
            <td className="inner_table text-center" colSpan={4}>No Records Found.</td>
          </tr>
        )
        return htmlData;
      }
      const { allIpEquipmentOrderList } = this.state;
      return allIpEquipmentOrderList.map((result, i) => {
        let htmlData = (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{result.serviceName}</td>
            <td>{moment(result.startTime).add(330,'minutes').format('DD MMM YYYY | hh:mm A ')}</td>
            <td>{result.stopTime != '0001-01-01T00:00:00' ? moment(result.stopTime).add(330,'minutes').format('DD MMM YYYY | hh:mm A ') : '-'}</td>
          </tr>
        )
        return htmlData;
      });
    } catch (err) {
      console.log('PatientIpEquipmentOrderLoad: ', err);
    }
  }

  PatientOpMedicineLoad(){
    const { allOpMedcineList } = this.state;
    if ((!this.state.allOpMedcineList || this.state.allOpMedcineList.length === 0)) {
      let htmlData = (
        <tr>
          <td className="inner_table text-center" colSpan={8}>No Records Found.</td>
        </tr>
      )
      return htmlData;
    }
    return (
        <Fragment>
            <MedicineTable
                drugsData={this.state.allOpMedcineList}
                viewType={"list-visit"}
                showAction={false}
                showScrollable={true}
                showSelectCheck={false}
                constants={this.state.drugsConstants}
            />
        </Fragment>
    );
  }

  PatientOpTestLoad(){
    const { allOpTestList } = this.state;
    if ((!this.state.allOpTestList || this.state.allOpTestList.length === 0)) {
      let htmlData = (
        <tr>
          <td className="inner_table text-center" colSpan={5}>No Records Found.</td>
        </tr>
      )
      return htmlData;
    }
    return (
        <Fragment>
            <TestTable
              testData={this.state.allOpTestList}
              viewType={"list-visit"}
              showAction={false}
              showScrollable={true}
              showSelectCheck={false}
              constants={this.state.testConstants}
            />
        </Fragment>
    );
  }

  PatientIpMedicineLoad = () => {
    if (this.state.allIpMedicineList.length === 0) {
      let htmlData = (
        <tr className="view_details">
          <td colSpan="17" className="inner_table text-center"><strong>No Records Found.</strong></td>
        </tr>
      )
      return htmlData;
    }
    var finalDrugSpan = {};
    var finalOrderSpan = {};
    if (this.state.allIpMedicineList && this.state.allIpMedicineList.length > 0) {

      var drugSpan = 1;
      var orderSpan = 1;
      var current_drug = '';
      var current_order;
      var order_drugs = {};
      for (const medinfo of this.state.allIpMedicineList) {
        if (medinfo.orderId == current_order) {
          if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
            orderSpan += medinfo.timeslots.length
          }  else {
            orderSpan += 1
          }
          
          order_drugs[medinfo.orderId].push(medinfo);
          var thisdrugorder = medinfo.orderId + 'S' + medinfo.serviceId;
          if (thisdrugorder == current_drug) {
            if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
              drugSpan += medinfo.timeslots.length
            } else {
              drugSpan += 1
            }
          }
          else {
            if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
              drugSpan = medinfo.timeslots.length;
            } else {
              drugSpan = 1;
            }
          }
        }
        else {
          order_drugs[medinfo.orderId] = [medinfo];
          if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
            orderSpan = medinfo.timeslots.length;
          } else {
            orderSpan = 1;
          }
          
          if(medinfo.dose_for_schedule_applicable && medinfo.taperdose_sn <= 1 && medinfo.timeslots.length >  1){
            drugSpan = medinfo.timeslots.length;
          } else {
            drugSpan = 1;
          }
        }
        current_drug = medinfo.orderId + 'S' + medinfo.serviceId;
        current_order = medinfo.orderId;
        finalDrugSpan[medinfo.orderId + 'S' + medinfo.serviceId] = drugSpan;
        finalOrderSpan[medinfo.orderId] = orderSpan;
      }
    }
    var current_drug_displayed;
    var current_order_displayed;
    var drug_spaned = true;
    var order_spaned = true;

    const { ipd_drug_constants } = this.state;
    const { dailyFrequency } = ipd_drug_constants;

    return this.state.allIpMedicineList.map((data, i) => {
      let ivForm = data.iV_drug_form && data.iV_drug_form != null ? data.iV_drug_form : '';
      var drugId = data.serviceId;
      let frequencyObjKey = typeof dailyFrequency != "undefined" ? dailyFrequency.find(item => item.id == data.daily_frequency) : null;
      var displayDurationForm = "";
      if (data.frequency_id == 2) {
        displayDurationForm = "Weeks"
      } else {
        if (frequencyObjKey != null && frequencyObjKey != "undefined") {
          if ((frequencyObjKey.nature == "Frequency" && frequencyObjKey.interval_per == "Days") || frequencyObjKey.nature == "Once") {
            displayDurationForm = "Days"
          }
          if (frequencyObjKey.nature == "Frequency" && frequencyObjKey.interval_per == "Weeks") {
            displayDurationForm = "Weeks"
          }
          if (frequencyObjKey.nature == "Frequency" && frequencyObjKey.interval_per == "Months") {
            displayDurationForm = "Months"
          }
        }
      }

      if (current_order_displayed == data.orderId) {
        order_spaned = false;
        var thisdrugorder = data.orderId + 'S' + data.serviceId;
        if (current_drug_displayed == thisdrugorder) {
          drug_spaned = false;
        }
        else {
          drug_spaned = true;
        }
      }
      else {
        order_spaned = true;
        drug_spaned = true;
      }
      current_drug_displayed = data.orderId + 'S' + data.serviceId;
      current_order_displayed = data.orderId;
      let htmlData = [];
      if (data.dose_for_schedule_applicable && data.taperdose_sn <= 1 && data?.timeslots?.length > 1){
        let counter = 1;
        data?.timeslots.map((t, j) => {
          if (counter == 1) {
            htmlData.push(
              <tr key={j} style={{ cursor: "pointer" }}>
                {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>
                  {data.orderNo ? data.orderNo : data.orderId} &nbsp;
                  {(data.orderRemarks === '' || data.orderRemarks === null) ? null :
                    <Tooltip placement="top" title={data.orderRemarks}>
                      <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} />
                    </Tooltip>
                  }
                </td> : null}
                {
                  order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{moment(data?.enteredDate).format('DD MMM YYYY | HH:mm A')}</td> : null
                }
                {
                  order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_Location}</td> : null
                }
                {
                  order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_by}</td> : null
                }
                {drug_spaned == true ?
                  <td style={{ verticalAlign: 'middle' }} rowSpan={finalDrugSpan[data.orderId + 'S' + data.serviceId]}>
                    {data.substituteissued == true ?
                      <Tooltip placement="top" title={data.substituteitemname} zIndex={999999}>
                        <span><i className="icon_substitutes" /> </span>
                      </Tooltip> : null
                    }
                    <div className="d-flex align-items-center">
                      <span className="pr-2">{data.service_name}</span>
                      {(data.remarks === '' || data.remarks === null) ? null :
                        <Tooltip placement="top" title={data.remarks}>
                          <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} className='mx-1' />
                        </Tooltip>
                      }
                      {
                        (data.discharge_medicine == false) ? null :
                          <span><img src={DischargeIcon} alt="discharge" className="existing-med-icon mx-1" style={{ width: '18px', height: 'auto' }} /></span>
                      }
                      {
                        (data.high_alert_medicine == false) ? null :
                          <span><img src={HighAlertIcon} alt="highalert" className="existing-med-icon mx-1" style={{width:'18px',height:'auto'}}/></span>
                      }
                      {
                        (data.controlled_substance_narcotic_alert_medicine && data.controlled_substance_narcotic_alert_medicine == true) ? <span className="bold"> NARCOTIC </span> :
                          null
                      }
                    </div>
                  </td>
                  : null}
                <td rowSpan={data?.timeslots?.length} className={data.give_stat_dose_now == true ? "text-danger" : ""}>{data.priority}</td>
                <td rowSpan={data?.timeslots?.length}>{moment(data.startMediDate).format("DD MMM YYYY | hh:mm A")}</td>
                { this.state.selectedEncounterData.encounterTypeCode == "IP" ?
                  <td rowSpan={data?.timeslots?.length}>{frequencyObjKey != null && frequencyObjKey.nature != "PRN" ? moment(data.endMediDate).format("DD MMM YYYY | hh:mm A") : "-"}</td>
                  :
                  null
                }
      
                <td rowSpan={data?.timeslots?.length}>{(frequencyObjKey != null && frequencyObjKey.nature != "PRN" && !data.give_stat_dose_now) ? data.number_of_days + " " + displayDurationForm : "-"}</td>
                <td rowSpan={data?.timeslots?.length}>{data.route}</td>
                <td rowSpan={data?.timeslots?.length}>{data.frequency_id == 2 && data.days_in_week_text ? data.daily_frequency_name + " (" + data.days_in_week_text + ")" : data.daily_frequency_name}</td>
                {data.show_additive ? 
                  <td>
                    {
                      !data.is_IV_addictive ?
                        data.dosage + ' ' + data.additive_volume_form + ' @' + data.additive_rate + ' ' + data.additive_rate_form
                        : <Tooltip placement="top" title={"With " + data.iV_drug_name + ' ' + data.additive_volume + ' ' + data.additive_volume_form + ' @' + data.additive_rate + ' ' + data.additive_rate_form}><img src={InfoIcon} alt="View" className="info-med-icon" style={{width:'18px',height:'auto'}} />{data.dosage} {data.uom_first_description !== null ? data.uom_first_description : ''}</Tooltip>
                    }
                  </td>
                  : <td>{t.dosage} {data.dosage_form_text !== null ? data.dosage_form_text : ''}</td>}
                <td>
                  <Moment parse="HH:mm" date={t.scheduletime} format="hh:mm A" />
                </td>
                <td>{t.instruction_desc}</td>
                <td rowSpan={data?.timeslots?.length}> {data.status}</td>
                <td rowSpan={data?.timeslots?.length}>{data?.cancel_reason && data?.cancel_reason != null ? data.cancel_reason : ''}</td>
              </tr>
            )
          } else {
            htmlData.push(
              <tr key={j}>
                <td>{(t.dosage != undefined) ? t.dosage + ' ' + (data.dosage_form_text) ?? data.dosage_form_text : '-'}</td>
                <td>
                  <Moment parse="HH:mm" date={t.scheduletime} format="hh:mm A" />
                </td>

                <td>{t.instruction_desc}</td>
              </tr>
            )
          }
          counter++;
        })
      } else {
        htmlData.push(
          <tr key={i} style={{ cursor: "pointer" }}>
            {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>
              {data.orderNo ? data.orderNo : data.orderId} &nbsp;
              {(data.orderRemarks === '' || data.orderRemarks === null) ? null :
                <Tooltip placement="top" title={data.orderRemarks}>
                  <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} />
                </Tooltip>
              }
            </td> : null}
            {
              order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{moment(data?.enteredDate).format('DD MMM YYYY | HH:mm A')}</td> : null
            }
            {
              order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_Location}</td> : null
            }
            {
              order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_by}</td> : null
            }
            {drug_spaned == true ?
              <td style={{ verticalAlign: 'middle' }} rowSpan={finalDrugSpan[data.orderId + 'S' + data.serviceId]}>
                {data.substituteissued == true ?
                  <Tooltip placement="top" title={data.substituteitemname} zIndex={999999}>
                    <span><i className="icon_substitutes" /> </span>
                  </Tooltip> : null
                }
                <div className="d-flex align-items-center">
                  <span className="pr-2">{data.service_name}</span>
                  {(data.remarks === '' || data.remarks === null) ? null :
                    <Tooltip placement="top" title={data.remarks}>
                      <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} className='mx-1' />
                    </Tooltip>
                  }
                  {
                    (data.discharge_medicine == false) ? null :
                      <span><img src={DischargeIcon} alt="discharge" className="existing-med-icon mx-1" style={{ width: '18px', height: 'auto' }} /></span>
                  }
                  {
                    (data.high_alert_medicine == false) ? null :
                      <span><img src={HighAlertIcon} alt="highalert" className="existing-med-icon mx-1" style={{ width: '18px', height: 'auto' }} /></span>
                  }
                  {
                    (data.controlled_substance_narcotic_alert_medicine && data.controlled_substance_narcotic_alert_medicine == true) ? <span className="bold"> NARCOTIC </span> :
                      null
                  }
                </div>
              </td>
              : null}
            <td className={data.give_stat_dose_now == true ? "text-danger" : ""}>{data.priority}</td>
            <td>{moment(data.startMediDate).format("DD MMM YYYY | hh:mm A")}</td>
            { this.state.selectedEncounterData.encounterTypeCode == "IP" ?
              <td>{frequencyObjKey != null && frequencyObjKey.nature != "PRN" ? moment(data.endMediDate).format("DD MMM YYYY | hh:mm A") : "-"}</td>
              :
              null
            }
  
            <td>{(frequencyObjKey != null && frequencyObjKey.nature != "PRN" && !data.give_stat_dose_now) ? data.number_of_days + " " + displayDurationForm : "-"}</td>
            <td>{data.route}</td>
            <td>{data.frequency_id == 2 && data.days_in_week_text ? data.daily_frequency_name + " (" + data.days_in_week_text + ")" : data.daily_frequency_name}</td>
            {data.show_additive ? 
              <td>
                {
                  !data.is_IV_addictive ?
                    data.dosage + ' ' + data.additive_volume_form + ' @' + data.additive_rate + ' ' + data.additive_rate_form
                    : <Tooltip placement="top" title={"With " + data.iV_drug_name + ' ' + data.additive_volume + ' ' + data.additive_volume_form + ' @' + data.additive_rate + ' ' + data.additive_rate_form}><img src={InfoIcon} alt="View" className="info-med-icon mr-2" style={{ width: '18px', height: 'auto' }} />{data.dosage} {data.uom_first_description !== null ? data.uom_first_description : ''}</Tooltip>
                }
              </td>
              : <td>{data.dosage} {data.dosage_form_text !== null ? data.dosage_form_text : ''}</td>}
            <td>
              {this.state.show_schedule_med_order ? (data?.schedule_name && data?.schedule_name != null && frequencyObjKey != null && frequencyObjKey.nature != "PRN" && frequencyObjKey.nature != "Once" ? data.schedule_name.replace(/-/g, ", ") : '-') : data?.timeslots && data?.timeslots.length ?
                <Tooltip placement="top" title={data?.timeslots.map((t, i) =>
                  <Moment key={i} parse="HH:mm" date={t.scheduletime} format="hh:mm A, " />
                )}>
                  {data?.timeslots.map((t, i) => {
                    return i < 6 ? <Moment key={i} parse="HH:mm" date={t.scheduletime} format="hh:mm A, " /> : ''
                  }
                  )}{data?.timeslots.length > 6 ? '...' : ''}
                </Tooltip>
                : '-'}
            </td>
            <td>{data.orderInstructions}</td>
            <td>{data.status}</td>
            <td>{data?.cancel_reason && data?.cancel_reason != null ? data.cancel_reason : ''}</td>
          </tr>
        )
      }
      return htmlData;
    });
  }

  PatientIpTestLoad = () => {
    if (this.state.allIpTestList.length === 0) {
      let htmlData = (
        <tr className="view_details">
          <td colSpan={13} className="inner_table text-center"><strong>No Records Found.</strong></td>
        </tr>
      )
      return htmlData;
    }

    var finalDrugSpan = {};
    var finalOrderSpan = {};
    if (this.state.allIpTestList && this.state.allIpTestList.length > 0) {
      var drugSpan = 1;
      var orderSpan = 1;
      var orderRemarks = '';
      var current_drug = '';
      var current_order;
      var order_drugs = {};
      for (const medinfo of this.state.allIpTestList) {
        if (medinfo.orderId == current_order) {
          
          orderSpan += 1
          order_drugs[medinfo.orderId].push(medinfo);
          var thisdrugorder = medinfo.orderId + 'S' + medinfo.serviceId;
          if (thisdrugorder == current_drug) {
            drugSpan += 1
          }
          else {
            drugSpan = 1;
          }
        }
        else {
          order_drugs[medinfo.orderId] = [medinfo];
          orderSpan = 1;
          drugSpan = 1;
        }
        current_drug = medinfo.orderId + 'S' + medinfo.serviceId;
        current_order = medinfo.orderId;
        finalDrugSpan[medinfo.orderId + 'S' + medinfo.serviceId] = drugSpan;
        finalOrderSpan[medinfo.orderId] = orderSpan;
      }
    }

    var current_drug_displayed;
    var current_order_displayed;
    var drug_spaned = true;
    var order_spaned = true;
    return this.state.allIpTestList.map((data, i) => {


      if (current_order_displayed == data.orderId) {
        order_spaned = false;
        var thisdrugorder = data.orderId + 'S' + data.serviceId;
        if (current_drug_displayed == thisdrugorder) {
          drug_spaned = false;
        }
        else {
          drug_spaned = true;
        }
      }
      else {
        order_spaned = true;
        drug_spaned = true;
      }
      if (data.orderRemarks != "" && data.orderRemarks != null) {
        orderRemarks = orderRemarks
      }
      current_drug_displayed = data.orderId + 'S' + data.serviceId;
      current_order_displayed = data.orderId;
      return (
        <tr className="align-middle my-2" key={i} style={{ cursor: "pointer" }}>
          {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>
            {data.orderNo ? data.orderNo : data.orderId}&nbsp;
            {(data.remarks === '' || data.remarks === null) ? null :
              <Tooltip placement="top" title={data.remarks} zIndex={8000}>
                <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: '14px' }} />
              </Tooltip>
            }
          </td> : null}
          {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{moment(data?.enteredDate).format('DD MMM YYYY | HH:mm A')}
          {typeof data.TatOutsourceLab!='undefined' && data?.TatOutsourceLab !=null ? <span className='float-right' style={{color:'black'}}><Tooltip placement="topLeft" arrowPointAtCenter={true} title={data?.TatOutsourceLab}>TAT</Tooltip></span> :  null}
          </td> : null}

          {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_Location}</td> : null}
          {order_spaned == true ? <td rowSpan={finalOrderSpan[data.orderId]}>{data.ordered_by}</td> : null}
          {drug_spaned == true ?
            <td style={{ verticalAlign: 'middle' }} rowSpan={finalDrugSpan[data.orderId + 'S' + data.serviceId]}>
              <span> {data.service_name}{data?.unit ? (' (' + data.unit + ')') : null} </span>
            </td>
            : null}

          <td>{data.orderInstructions ? data.orderInstructions : ''}</td>
          <td>{data.doctorName ? data.doctorName : ''}</td>
          <td>{data.department_name}</td>
          <td>{data.priority}</td>
          <td>{data.requisition_comments != null && data.requisition_comments != '' ? <a className='text-primary' onClick={() => this.showTestModalInteraction(data.requisition_comments)}><u>View Requisition Comments</u></a> : <span>No</span>}</td>
          {
            data?.investigationStatus?.toUpperCase().trim() === "FINALIZED" ||
              data?.investigationStatus?.toUpperCase().trim() === "REPORTED" ||
              data?.investigationStatus?.toUpperCase().trim() === "RD" ||
              data?.investigationStatus?.toUpperCase().trim() === "RP" ?
              <td style={{ width: '20%' }}>
                <span onClick={this.ResultViewPopup.bind(this, data.orderId, data.orderNo, data.serviceId, data.id)} className="cursor-p text-success">{data.status}</span>&nbsp;&nbsp;
                {data.departmentType === "RIS" && data.imageURL !== null && data.imageURL !== ''
                  ?
                  <FontAwesomeIcon icon={faRadiationAlt} className="text-warning cursor-p" style={{ fontSize: '17px' }}
                    onClick={() => window.open(data.imageURL, "_blank", "noreferrer")}
                  />
                  : ""}
              </td> :
              data?.investigationStatus?.toUpperCase().trim() === "RE"
                ?
                <td style={{ width: '20%' }}>
                  <span onClick={this.ResultEntryPopup.bind(this, data)} className="cursor-p text-success">{data.status}</span>&nbsp;&nbsp;
                </td> :
                data?.investigationStatus?.toUpperCase().trim() === "RA"
                  ?
                  <td style={{ width: '20%' }}>
                    <span onClick={this.ResultAuthorizedPopup.bind(this, data)} className="cursor-p text-success">{data.status}</span>&nbsp;&nbsp;
                    {data?.investigationStatus?.toUpperCase().trim() === "RA" && data.attachedimageURL !== null && data.attachedimageURL !== '' && data.attachedimageURL !== "undefined"
                      ?
                      <FontAwesomeIcon icon={faRadiationAlt} className="text-warning cursor-p" style={{ fontSize: '17px' }}
                        onClick={() => window.open(data.attachedimageURL, "_blank")}
                      />

                      : ""}
                  </td>
                  :
                  data?.investigationStatus?.toUpperCase().trim() === "HLD" ?
                    <td style={{ width: '20%', color: 'red' }}>
                      <Tooltip placement="topLeft" arrowPointAtCenter={true} title={data?.holdReason ? data?.holdReason : ""}>
                        <span className="cursor-p"> {data.status}  </span>
                      </Tooltip></td>
                    :
                    <td style={{ width: '20%', color: `${data.status == 'Rejected' || data.status == 'Sample Not Collected' ? 'red' : null}` }}>  <Tooltip placement="topLeft" arrowPointAtCenter={true} title={data?.cancel_reason}><span> {data.status}  </span>
                    </Tooltip></td>
          }
             
        </tr>
      )
    });
  }

  ResultViewPopup = async (orderId, orderNo, serviceId, serviceorderdtl_id) => {
    let data = await this.fetchServiceDetails(LS_SERVICE.get('ENTERPRISE_ID'), LS_SERVICE.get('FACILITY_ID'), 0, this.state.selectedEncounterData.encounter_id, serviceId, orderId, serviceorderdtl_id);
    if (data.length > 0) {
      await this.setState({ ServiceDetailsData: data , orderDetailsModal: true });
    }
  }

  fetchServiceDetails = async (enterprise_id, facility_id, registration_id, encounter_id, service_id, serviceorder_id, serviceorderdtl_id, serviceorderNo) => {
    const Parameter = {
      enterprise_id: enterprise_id,
      facility_id: facility_id,
      registration_id: registration_id,
      registration_no: "",
      encounter_id: encounter_id,
      encounter_no: "",
      serviceorder_id: serviceorder_id,
      serviceorder_no: serviceorderNo,
      serviceorderdtl_id: serviceorderdtl_id,
      service_id: service_id,
      service_name: "",
      fromDate: null,
      toDate: null,
      userId: LS_SERVICE.get('staff_id')
    }
    let data = {};
    try {
      await Axios.post(GET_LIS_ORDER_RESULT_DATA,
        Parameter, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',

        }
      }).then((response) => {
        data = response.data.data
      })
    }
    catch (error) {
      data = error
    }
    return data;
  }

  ResultEntryPopup = async (p) => {
    let data = await this.fetchProcedureTypeDatas(15, 1, '', 0, LS_SERVICE.get('FACILITY_ID'), LS_SERVICE.get('ENTERPRISE_ID'), '', p.orderNo.replace("Test - ", ''), '', p.endMediDate, p.endMediDate, 0, 0, 0)
    if (data.length > 0) {
      let dat = data[0].editorText;
      await this.setState({ editorText: dat })
    }

    await this.setState({
      PR_serviceName: p.service_name
      , PR_orderNo: p.orderNo.replace("Test -", '')
      , PR_orderDate: p.enteredDate
      , PR_Code: "RP"
      , PR_authorisedById: LS_SERVICE.get('staff_id')
      , PR_acknowledgeDatetime: p.endmedicationdatetime
      , pr_reportedDatetime: p.enteredDate

    })
    await this.handleshowResultView("resultEntrypage");

  }

  handleshowResultView = (res) => {
    if (res === "resultEntrypage") {
      this.setState({ showResultView: true })
    }
  }

  handleClosemodalResultView = (res) => {
    if (res === "resultEntrypage") {
      this.setState({ showResultView: false })
    }
  }

  ResultAuthorizedPopup = async (q) => {
    let data = await this.fetchProcedureTypeDatas(15, 1, '', 0, LS_SERVICE.get('FACILITY_ID'), LS_SERVICE.get('ENTERPRISE_ID'), '', q.orderNo.replace("Test - ", ''), '', q.endMediDate, q.endMediDate, 0, 0, q.serviceId)
    if (data.length > 0) {
      let dat = data[0].resultEntryUrl
      if (typeof dat !== "undefined" && dat !== null) {
        window.open(dat, "_blank")
      }
    }
  }

  fetchProcedureTypeDatas = async (PageSize, currentPageForSearch, encounterTypeCode, investigationStatusId, facility_id, enterprise_id, encounter_no, serviceorder_no, uhid, fromDate, toDate, departmentId, departmentSubId, insServiceId) => {
    const Parameter = {
      registration_no: uhid,
      fromDate: fromDate,
      toDate: toDate,
      facility_id: parseInt(facility_id),
      enterprise_id: enterprise_id,
      encounter_no: encounter_no,
      serviceorder_no: serviceorder_no,
      encounterTypeCode: encounterTypeCode,
      investigationStatusId: parseInt(investigationStatusId),
      PageSize: PageSize,
      PageNo: currentPageForSearch,
      departmentId: parseInt(departmentId) || 0,
      departmentSubId: parseInt(departmentSubId) || 0,
      service_id: parseInt(insServiceId) || 0
    }
    let data = {};
    try {
      await Axios.post(GET_PROCEDURE_TYPE_SERVICE_DATA,
        Parameter, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',

        }
      }).then((response) => {
        data = response.data.data
      })
    }
    catch (error) {
      data = error
    }
    return data;
  }


  orderDetailsModalClose = () =>{
    this.setState({ orderDetailsModal :false, ServiceDetailsData: []})
  }

  orderTimelineModalClose = () =>{
    this.setState({ orderTimelineModal: false })
  }
  
  render() {
    const { loader, selectedEncounterData } = this.state;
    return (
      <>
        { loader ? 
          <div className='row mt-3'>
            <div className="col-12  text-center mt-4">{LOADER_RED}
            </div>
          </div>
          :
          <div className='row mt-3'>
            <div className='col-12'>
              <ul className="nav nav-pills myhealthcare-tabs auto-width test-results-tabs mb-0 mt-2" id="pills-order-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                      <a className="nav-link active" id="pills-tests-procedure-order-tab" data-toggle="pill" href="#pills-tests-procedure-order" role="tab" aria-controls="pills-tests-procedure-order" aria-selected="true">Tests/Procedure Order</a>
                  </li>
                  <li className="nav-item" role="presentation">
                      <a className="nav-link" id="pills-medication-order-tab" data-toggle="pill" href="#pills-medication-order" role="tab" aria-controls="pills-medication-order" aria-selected="false">Medication Order</a>
                  </li>
                  { selectedEncounterData.encounterTypeCode !== "OP" ?
                    <>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="pills-equipment-order-tab" data-toggle="pill" href="#pills-equipment-order" role="tab" aria-controls="pills-equipment-order" aria-selected="false">Equipment Order</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="pills-other-order-tab" data-toggle="pill" href="#pills-other-order" role="tab" aria-controls="pills-other-order" aria-selected="false">Other Order</a>
                    </li>
                    </>
                    :
                    null
                  }
              </ul>

              <div className="tab-content p-2" style={{ border: 'solid 1px #ccc' }} id="pills-order-tabContent">

                {/* ### tab content for Tests/Procedure Order tab ### */}
                <div className="tab-pane fade show active" id="pills-tests-procedure-order" role="tabpanel" aria-labelledby="pills-tests-procedure-order-tab">
                    { selectedEncounterData.encounterTypeCode == "OP" ?
                      this.PatientOpTestLoad()
                      :
                      <>
                        <div className='row mt-3'>
                          <div className='col-12'>
                          <div className="table-responsive scrollable_sub_table">
                              <table className='table table-bordered '>
                                <thead>
                                  <tr>
                                    <th style={{width:'120px',minWidth:'120px'}} className="text-left align-middle">ORDER ID</th>
                                    <th style={{width:'120px',minWidth:'120px'}} className="text-left align-middle">DATE & TIME</th>
                                    <th style={{width:'150px',minWidth:'150px'}} className="text-left align-middle">ORDERING LOCATION</th>
                                    <th style={{width:'130px',minWidth:'140px'}} className="text-left align-middle">ORDERED BY</th>
                                    <th style={{width:'170px',minWidth:'190px'}} className="text-left align-middle">ORDER NAME</th>
                                    <th style={{width:'130px',minWidth:'150px'}} className="text-left align-middle">INSTRUCTION</th>
                                    <th style={{width:'130px',minWidth:'130px'}} className="text-left align-middle">PERFORMING PRACTITIONER </th>
                                    <th style={{width:'140px',minWidth:'140px'}} className="text-left align-middle">DEPARTMENT</th>
                                    <th style={{width:'85px',minWidth:'85px'}} className="text-left align-middle">PRIORITY</th>
                                    <th style={{width:'130px',minWidth:'130px'}} className="text-left align-middle">REQUISITION COMMENTS</th>
                                    <th style={{width:'110px',minWidth:'180px'}} className="text-left align-middle">STATUS</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.PatientIpTestLoad()}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                </div>
                {/* ### end of  Tests/Procedure Order tab ### */}

                {/* ### tab content for Medication Order tab ### */}
                <div className="tab-pane fade" id="pills-medication-order" role="tabpanel" aria-labelledby="pills-medication-order-tab">
                    { selectedEncounterData.encounterTypeCode == "OP" ?
                      this.PatientOpMedicineLoad()
                      :
                      <>
                        <div className='row mt-3'>
                          <div className='col-12'>
                          <div className="table-responsive scrollable_sub_table">
                            <table className='table table-bordered '>
                                <thead>
                                  <tr>
                                    <th style={{width: '120px', minWidth: '120px' }} className="text-left align-middle">ORDER ID</th>
                                    <th style={{ width: '120px', minWidth:'120px'}}  className="text-left align-middle">DATE & TIME</th>
                                    <th style={{ width: '140px', minWidth:'140px'}}  className="text-left align-middle">ORDERING LOCATION</th>
                                    <th style={{ width: '130px', minWidth:'130px'}}  className="text-left align-middle">ORDERED BY</th>
                                    <th style={{ width: '170px', minWidth:'170px'}}  className="text-left align-middle">ORDER NAME</th>
                                    <th style={{ width:'70px',minWidth:'70'}}  className="text-left align-middle">PRIORITY</th>
                                    <th style={{ width: '120px', minWidth:'120px'}}  className="text-left align-middle">START DATE</th>
                                    { this.state.selectedEncounterData.encounterTypeCode == "IP" ?
                                      <th style={{ width: '120px', minWidth:'120px'}}  className="text-left align-middle">END DATE</th>
                                      :
                                      null
                                    }
                                    <th style={{ width: '80px',   minWidth:'80px'}}  className="text-left align-middle">DURATION</th>
                                    <th style={{ width: '80px', minWidth:'80px'}}  className="text-left align-middle">ROUTE</th>
                                    <th style={{ width: '80px', minWidth:'80px'}}  className="text-left align-middle">FREQUENCY</th>
                                    <th style={{ width: '80px', minWidth:'80px'}}  className="text-left align-middle">VOL/DOSAGE</th>
                                    <th style={{ width: '90px', minWidth:'90px'}}  className="text-left align-middle">SCHEDULE</th>
                                    <th style={{ width: '100px', minWidth:'100px'}}  className="text-left align-middle">INSTRUCTION</th>
                                    <th style={{ width: '90px', minWidth:'90px'}}  className="text-left align-middle">STATUS</th>
                                    <th style={{width:'90px',minWidth:'90px'}} className="text-left align-middle">REASON</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.PatientIpMedicineLoad()}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                </div>
                {/* ### end of  Medication Order tab ### */}

                { selectedEncounterData.encounterTypeCode !== "OP" ?
                  <>
                  <div className="tab-pane fade" id="pills-equipment-order" role="tabpanel" aria-labelledby="pills-equipment-order-tab">
                      <div className='row mt-3'>
                      <div className='col-12'>
                          <div className="table-responsive scrollable_sub_table">
                            <table className='table table-bordered '>
                            <thead>
                              <tr>
                                <th width="10%" className="text-left align-middle">S.NO</th>
                                <th width="20%" className="text-left align-middle">EQUIPMENT</th>
                                <th width="20%" className="text-left align-middle">START TIME</th>
                                <th width="20%" className="text-left align-middle">END TIME</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.PatientIpEquipmentOrderLoad()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="pills-other-order" role="tabpanel" aria-labelledby="pills-other-order-tab">
                    <div className='row mt-3'>
                      <div className='col-12'>
                          <div className="table-responsive scrollable_sub_table">
                            <table className='table table-bordered '>
                              <thead>
                                <tr>
                                  <th width="6%" className="text-left align-middle">S.NO</th>
                                  <th width="16%" className="text-left align-middle">ORDER NO</th>
                                  <th width="20%" className="text-left align-middle">DOCTOR NAME</th>
                                  <th width="16%" className="text-left align-middle">DATE/TIME</th>
                                  <th width="18%" className="text-left align-middle">NURSING UNIT</th>
                                  <th width="10%" className="text-left align-middle">PRIORITY</th>
                                  <th width="" className="text-left align-middle">OTHER DESCRIPTION</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.PatientIpOtherOrderLoad()}
                              </tbody>
                            </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  </>
                  :
                  null
                }
              </div>
            </div>
          </div>
        }

        <Modal
            className="myTestDrugsInteraction_modal"
            id="myTestDrugsInteraction"
            title="Requisition Comments"
            visible={this.state.isTestModalInteractionVisible}
            onOk={() => this.handleTestInteractionCancel()}
            onCancel={() => this.handleTestInteractionCancel()}
            footer={false}
          >

          <div className="col-12">
            <textarea readOnly name="noi-comments" id="noi-comments" data-name="noi-comments" value={this.state.commentValue} className="form-control drug-input input-template mandatory-field" placeholder="Comments" rows="4" cols="50"></textarea>
          </div>

          <div className="modal-footer justify-content-center emrfrm pb-0">
            <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={() => this.handleTestInteractionCancel()}>CLOSE</button>
          </div>
        </Modal>

        <Modal
          title="Result View"
          visible={this.state.showResultView}
          onCancel={() => this.handleClosemodalResultView("resultEntrypage")}
          style={{ cursor: 'auto' }}
          width={1200}
          closable={true}
          footer={false}
        >
          <div id="DivEditor" className='row'>
            <ResultEntryEditors
              PR_serviceName={this.state.PR_serviceName}
              editorText={this.state.editorText}
              isEditor={true}
              PR_orderNo={this.state.PR_orderNo}
              PR_orderDate={this.state.PR_orderDate}
              PR_Code={"RP"}
              PR_patientName={LS_SERVICE.get('PATIENT_REG_DATA').full_name}
              PR_authorisedById={this.state.PR_authorisedById}
              PR_ageGender={LS_SERVICE.get('PATIENT_REG_DATA').ageDes + ',' + LS_SERVICE.get('PATIENT_REG_DATA').genderCode}
              PR_acknowledgeDatetime={this.state.PR_acknowledgeDatetime}
              pr_reportedDatetime={this.state.pr_reportedDatetime}
              PR_registrationNo={LS_SERVICE.get('PATIENT_REG_DATA').registrationNo}
              PR_encounterNo={this.state.selectedEncounterData.encounterNo}>
            </ResultEntryEditors>

          </div>

          {/* </Modal.Body> */}
        </Modal>

        
        {/* Order Details Modal */}
        <Modal
          title="Order Details"
          id="order_detailsmodal"
          visible={this.state.orderDetailsModal}
          onCancel={() => this.orderDetailsModalClose()}
          footer={false}
          width={"80%"}
        >
          <div className='row'>
            <div className='col-12'>
              <div className="banner-cardopd patient-card-opd patient-red-opd">
                <div className="row">
                  <div className="col-2">
                    <label className="text-muted mb-0 mr-2">UHID</label>
                    <label className="font-weight-bold mb-0 text-dark">{this.state.patientBannerData.registrationNo}</label>
                    <br />
                  </div>
                  <div className="col-3">
                    <label className="text-muted mb-0 mr-2">Name</label>
                    <label className="font-weight-bold mb-0 text-dark">{this.state.patientBannerData.name}</label>
                  </div>
                  <div className="col-2">
                    <label className="text-muted mb-0 mr-2">AGE/GENDER : </label>
                    <label className="font-weight-bold mb-0 text-dark">{this.state.patientBannerData.ageGender}</label>
                  </div>
                  <div className="col-3">
                    <label className="text-muted mb-0 mr-2">Encounter No</label>
                    <label className="font-weight-bold mb-0 text-dark">{this.state.patientBannerData.encounterNo}</label>
                  </div>
                  <div className="col-2">
                    <label className="text-muted mb-0 mr-2">Order No</label>
                    <label className="font-weight-bold mb-0 text-dark">{this.state.ServiceDetailsData[0]?.serviceorder_no}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            (this.state.ServiceDetailsData.length > 0) ?
              this.ServiceDetailsDataLoad()
              :
            null
          }
        </Modal>

        <Modal
          title="Print"
          visible={this.state.ShowPopUP}
          footer={false}
          width={950}
          onCancel={() => { this.setState({ ShowPopUP: false, reultLink: null }) }}
          style={{ top: 20 }}
        >
          <div className="row">
              <div className="col-12 my-3 text-center">
                  {this.state.reultLink != null ? <iframe src={this.state.reultLink} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                      {LOADER_RED}
                  </iframe> : null}
              </div>
          </div>
        </Modal>


    {/* Order Timeline Modal */}
        <Modal
          title="Order Timeline"
          visible={this.state.orderTimelineModal}
          onCancel={() => this.orderTimelineModalClose()}
          footer={false}
          width={"75%"}
        >
          <div className='row'>
            <div className='col-12'>
              <div className="banner-cardopd patient-card-opd patient-red-opd">
                <div className="row">
                  <div className="col-3">
                    <label className="text-muted my-1 mr-2">UHID</label>
                    <label className="font-weight-bold my-1 text-dark">230281918</label>
                    <br />
                  </div>
                  <div className="col-3">
                    <label className="text-muted my-1 mr-2">Name</label>
                    <label className="font-weight-bold my-1 text-dark">Mr Sarfaraz Malik- 24 Years/M</label>
                  </div>
                  <div className="col-3">
                    <label className="text-muted my-1 mr-2">Mobile No.</label>
                    <label className="font-weight-bold my-1 text-dark">8905604844</label>
                  </div>
                  <div className="col-3">
                    <label className="text-muted my-1 mr-2">Bed No</label>
                    <label className="font-weight-bold my-1 text-dark">SICG7</label>
                  </div>
                  <div className="col-3">
                    <label className="text-muted my-1 mr-2">Ref Hospital</label>
                    <label className="font-weight-bold my-1 text-dark">Sandbox Paras Hospitals, Gurugram</label>
                  </div>
                  <div className="col-3">
                    <label className="text-muted my-1 mr-2">Practitioner</label>
                    <label className="font-weight-bold my-1 text-dark">Ashiya Mahran</label>
                  </div>
                  <div className="col-3">
                    <label className="text-muted my-1 mr-2">Ref Practitioner</label>
                    <label className="font-weight-bold my-1 text-dark">Ashiya Mahran</label>
                  </div>
                  <div className="col-3">
                    <label className="text-muted my-1 mr-2">Order ID</label>
                    <label className="font-weight-bold my-1 text-dark">GGN0749941</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-4'>
            <div className="col-lg-2 col-3">
              <ul className="d_timeline_status_latest" >
                <li className="completed"><i />Completed</li>
                {/* <li className="inprogress"><i />In Progress</li> */}
                <li className="completed-late"><i />Completed Late</li>
                <li className='negative-flow'><i />Exception</li>
                <li><i />Pending</li>
                <li className="na"><i />N/A</li>
              </ul>
            </div>

            <div className="col text-center">
              <div className="row ">
                <div className='col-12 text-center mb-3'>
                  <button className="btn btn-outline-secondary px-2 py-1">View History</button>
                </div>

                <div className="col-12 text-center">
                  <div className="order-timeliness">
                    <ul className=''>
                      <li>
                        <div className={`order-left completed`}>
                          <div className='text-right'>
                            Order Placed
                            <br />
                            VAIBHAV
                            <br />
                            RECEIVED AT PF
                          </div>
                        </div>
                        <div className='order-right'>
                          TAT (minutes) - 0
                          <br />
                          24-Jul-24 | 10:42 AM
                        </div>
                      </li>
                      <li>
                        <div className={`order-left completed`}>
                          <div className='text-right'>
                            Sample Collected:
                            <br />
                            VAIBHAV
                          </div>
                        </div>
                        <div className='order-right'>
                          TAT (minutes) - N/A
                          <br />
                          24-Jul-24 | 10:42 AM
                        </div>
                      </li>
                      <li>
                        <div className={`order-left pending`}>
                          <div className='text-right'>
                            Dispatched To Lab:
                            <br />
                            VAIBHAV
                          </div>
                        </div>
                        <div className='order-right'>
                          TAT (minutes) - 0
                          <br />
                          24-Jul-24 | 10:42 AM
                        </div>
                      </li>
                      <li>
                        <div className={`order-left completed-late`}>
                          <div className='text-right'>
                            Sample Acknowledged:
                            <br />
                            VAIBHAV
                          </div>
                        </div>
                        <div className='order-right'>
                          TAT (minutes) - 0
                          <br />
                          24-Jul-24 | 10:42 AM
                        </div>
                      </li>
                     

                    </ul>
                  </div>
                </div>
              </div>
            </div>


          </div>

        </Modal>

      </>
    )
  }
}
